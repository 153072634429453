import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "../config/axios";
import notification from "../components/notification";
import saveToken from "../utils/saveToken";
import { cmsAuthActions } from ".";

export const postSignin = createAsyncThunk(
    "auth/postSignin",
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.post("/admin/auth/signin", params.formData);
            dispatch(cmsAuthActions.setUser(response.data.token))
            params.navigate();
            window.location.reload()

            return response.data.token;
        } catch (err) {
            return rejectWithValue("Failed to signin!");
        }
    }
)
export const postSignup = createAsyncThunk("auth/postSignup", async (params, { rejectWithValue, dispatch }) => {
    try {
        const response = await axios.post("/admin/auth/signup", params.formData);
        dispatch(cmsAuthActions.setUser(response.data.token))
        return response.data.token;
    } catch (err) {
        return rejectWithValue("Failed to signup!");
    }
})

export const updateUser = createAsyncThunk("auth/updateUser", async (params, { rejectWithValue, dispatch }) => {
    try {
        const response = await axios.post(`/admin/auth/update/user/${params.userId}`, params.formData)
        dispatch(cmsAuthActions.setUser(response.data.token));
        return response.data.token;
    } catch (e) {
        return rejectWithValue("Failed to update user!");
    }
})

export const checkToken = createAsyncThunk("auth/checkToken", async (params, { rejectWithValue, dispatch }) => {
    const token = localStorage.getItem("token");
    try {
        if (!token) throw new Error("No token")
        const response = await axios.get(`/admin/auth/check/token/${token}`)
        dispatch(cmsAuthActions.initializeAuth())
    }
    catch (e) {
        return rejectWithValue("Failed to update user!");
    }
})


export const resetPassword = createAsyncThunk("auth/resetPassword", async (params, { rejectWithValue, dispatch }) => {
    try {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("No token")
        const response = await axios.put(`/admin/auth/resetpassword`, params.formData)
        dispatch(cmsAuthActions.logout())
        params.navigate();
        return response.data.token;
    }
    catch (e) {
        return rejectWithValue("Failed to update user!");
    }
})

const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: null,
    },
    reducers: {
        setUser(state, { payload }) {
            const encodedPayload = payload.split('.')[1];
            state.user = JSON.parse(atob(encodedPayload));
        },
        logout(state, ___) {
            localStorage.removeItem("token");
            state.user = null;
        },
        initializeAuth(state, { payload }) {
            const token = localStorage.getItem("token");
            state.user = token ? JSON.parse(atob(token.split(".")[1])) : null;
        },
    },
    extraReducers: {
        [postSignin.fulfilled]: (state, action) => {
            saveToken(action.payload)
            notification("success", { message: "Success.", description: "Successfully signed in." });
        },
        [postSignin.rejected]: (state) => {
            state.user = null;
            notification("error", { message: "Error!", description: "Try again! " });
        },
        [postSignup.fulfilled]: (state, action) => {
            saveToken(action.payload)
            notification("success", { message: "Success.", description: "Successfully signed up." });
        },
        [postSignup.rejected]: (state) => {
            state.user = null;
            notification("error", { message: "Error!", description: "Server error or validation error! " });
        },
        [updateUser.fulfilled]: (state, action) => {
            saveToken(action.payload)
            notification("success", { message: "Success.", description: "Successfully updated." });
        },
        [updateUser.rejected]: (state) => {
            state.user = null;
            notification("error", { message: "Error!", description: "Server error or validation error! " });
        },
        [resetPassword.fulfilled]: (state, action) => {

            notification("success", { message: "Success.", description: "Password changed. PLease login once more." });
        },
        [resetPassword.rejected]: (state) => {
            notification("error", { message: "Error!", description: "Server error or validation error! " });
        },
        [checkToken.rejected]: (state => {
            state.user = null;
        })
    }
})
export default authSlice;